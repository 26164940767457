import React, { useState, useEffect, useCallback, useRef } from "react";
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import { SubAdminSchema, SuperAdminSchema } from "../../Helpers/Constants/Schemas";
import { SubAdminFields, SuperAdminFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CREATE, UPDATE } from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { Country, State, City } from 'country-state-city';
import { LIMIT1, NA, OFFSET } from "../../Helpers/Constants/Default";
import CustomSelect from "./CustomSelect";
import Permission from "../Common/Permission";

const SubAdmin = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [roleId, setRoleId] = useState("");
  const [subAdminId, setSubAdminId] = useState("");
  const [permissions, setPermissions] = useState([]);

  const [stateLabel, setStateLabel] = useState('');
  const [statesCode, setStatesCode] = useState('');




  const superpermission = [
    {
      "field": "agents",
      "name": "Agents",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "supervisors",
      "name": "Supervisors",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "managers",
      "name": "Managers",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "subAdmins",
      "name": "Area Head",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "admins",
      "name": "Regional Head",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "superAdmins",
      "name": "Root User",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "notify",
      "name": "Notification",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "config",
      "name": "Config",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "superLogout",
      "name": "Super Logout",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "cms",
      "name": "CMS",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    }
  ]
  const [isUpdate] = useState(state ? true : false);
  const [initialValues] = useState(isUpdate ? { ...state, permissions: superpermission } : { ...SuperAdminFields.required, permissions: superpermission });


  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState([]);
  const [manager, setManageList] = useState([]);
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT1,
    offset: paginate?.subAdmin || OFFSET,
  });

  const getStates = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.LOCATION.STATES);
      if (status) {
        setStatesAndNewUTs(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  const getCities = async (stateCode) => {
    if (!stateCode) {
      console.log("State code is required to fetch cities.");
      return;
    }

    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(`${API.LOCATION.CITY}?stateCode=${stateCode}`);
      if (status) {
        setNewCityOptions(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };



  useEffect(() => {
    getStates();
  }, [filter]);

  useEffect(() => {
    const city = City.getCitiesOfState("IN")
    console.log(city);
  }, [])

  const [admindata, setAdminData] = useState([]);
  const [statesAndNewUTs, setStatesAndNewUTs] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [newemployees, setNewEmployees] = useState([]);

  const [selectedemployees, setSelectedEmployees] = useState([]);

  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.ADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });
      console.log(data.managers)
      console.log(data)
      console.log(status)
      if (status === true) {
        setData(data);
        setManagers(data.data || []);
        console.log(data.data)
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };
  const [accountType, setAccountType] = useState(null);

  const handleManagerChange1 = (event) => {
    const selectedManagerObject = event.target.value;
    console.log(event.target.value)
    setSelectedManager(selectedManagerObject);
  };


  const getDataSure = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.ADMIN.PARENT, {
      });
      console.log(data.managers)
      console.log(data)
      console.log(status)
      if (status === true) {
        setData(data);
        setManagers([data] || []);
        console.log(data.name)
        console.log(data.accountType)
        setAccountType(data.accountType);

        console.log(managers)
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    console.log("Filter changed:", filter);
    getDataSure();
  }, [filter]);



  const getAdminData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.MANAGER.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });
      console.log(admindata.managers)
      console.log(admindata)
      console.log(status)
      if (status === true) {
        setAdminData(data);
        setEmployees(data.data || []);
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  // useEffect(() => {
  //   console.log("Filter changed:", filter);
  //   getData();
  // }, [filter]);

  const getDataCallback = useCallback(() => {
    if (accountType !== null && accountType !== 2 && accountType !== 3) {
      getData();
    }
  }, [accountType, getData]);

  useEffect(() => {
    console.log("Filter changed:", filter);
    getDataCallback();
  }, [filter, accountType]);

  useEffect(() => {
    console.log("Filter changed:", filter);
    getAdminData();
  }, [filter]);

  const handleManagerChange = (event) => {
    const selectedValue = event.target.value;
    const selectedManagerObject = managers.find(manager => manager._id === selectedValue);
    setSelectedManager(selectedManagerObject);
  };

  // const groupedByState = newemployees.

  const handleEmployeeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedEmployeeObject = employees.find(emp => emp._id === selectedValue);

    if (selectedEmployeeObject) {
      setSelectedEmployees(prevSelected => {
        if (prevSelected.some(emp => emp._id === selectedValue)) {
          return prevSelected.filter(emp => emp._id !== selectedValue);
        } else {
          return [...prevSelected, selectedEmployeeObject];
        }
      });
    }
  };

  const [statesAndUTs, setStatesAndUTs] = useState([]);

  const [customState, setCustomState] = useState('');

  const [cities, setCities] = useState([]);
  const [newCity, setNewCity] = useState('');
  const [state1, setState1] = useState("");
  const [city1, setCity1] = useState("");
  const [state2, setState2] = useState(null);
  const [city, setCity] = useState([]);
  const [updatecity, setUpdatecity] = useState(null);

  useEffect(() => {
    setStatesAndUTs(State.getStatesOfCountry("IN"))
  }, [])


  useEffect(() => {
    const statelist = State.getStatesOfCountry("IN")
    setStatesAndUTs(statelist)
    const statedata = statelist?.find((e) => (e.name?.toLowerCase() == initialValues?.state?.toLowerCase()))
    console.log(statedata)
    console.log(statelist)
    handleStateChange(statedata?.isoCode + "_" + statedata?.name, true)

  }, [])

  const handleAddState = () => {
    if (customState && !statesAndUTs.some(state => state.name === customState)) {
      setStatesAndUTs([...statesAndUTs, { name: customState }]);
      setCustomState('');
    }
  };

  const handleAddCity = () => {
    if (newCity && !cities.some(city => city.name === newCity)) {
      setCities([...cities, { name: newCity }]);
      setNewCity('');
    }
  };

  const validateUserName = (value) => {
    const regex = /^[a-zA-Z0-9.]*$/;
    if (!regex.test(value)) {
      return "User ID must be alphanumeric and may include periods.";
    }
    return undefined;
  };


  const handleSubmit = async (data) => {
    const { state, city, ...rest } = data;
    delete rest.city2;
    delete rest.name;

    const selectedCity = cityOptions.find(item => item.label === city);

    const payload = {
      ...rest,
      address: {
        state: stateLabel,
        stateCode: statesCode,
        city: selectedCity ? selectedCity.label : city,
        country: "India",
      },
    };

    if (selectedManager) {
      payload.parentId = selectedManager._id || selectedManager;
    }

    if (selectedemployees.length > 0) {
      payload.userList = selectedemployees.map(emp => emp._id);
    }

    try {
      const { status } = await Axios.post(API.SUB_ADMIN.CREATE, payload);
      if (status) {
        navigate(typeToRoute[data.type].create);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };
  const handleManagerList = (value) => {
    console.log(value)
    const groupedByState = value.reduce((acc, employee) => {
      const state = employee.address.state;
      if (!acc[state]) {
        acc[state] = [];
      }
      acc[state].push(employee);
      return acc;

    }, {});

    console.log(groupedByState)
    const data = [];
    for (let [_a, state] of Object.entries(groupedByState)) {
      data.push({
        value: "not_allowed_custom",
        label: <span
          style={{
            fontWeight: 600,
            fontSize: "1rem", // Suitable size for dropdown
            color: "black",
            textTransform: "uppercase", // Makes text uppercase
            padding: "5px 10px", // Add padding for spacing
            borderBottom: "1px solid #ccc", // Optional: Bottom border for separation
            backgroundColor: "#f8f8f8", // Light background color for contrast
            display: "block", // Ensures it takes full width in the dropdown
            marginBottom: "5px", // Space below the heading
          }}
        >
          {_a?.toUpperCase()}
        </span>

      })
      for (let value of state) {
        data.push({
          value: value._id,
          label: value.name
        })
      }
    }
    console.log(data)
    return setManageList(data)
  }
  const handleUpdateDocument = async (data) => {
    const { state, city,stateCode, userList } = data;

    const addressObject = {};
    if (stateLabel) {
      addressObject.state = stateLabel;
    }
    if (statesCode) {
      addressObject.stateCode = statesCode;
    }
    if (city) {
      const selectedCity = newcityOptions.find(item => item.label === city);
      addressObject.city = selectedCity ? selectedCity.label : city;
    }

    if (Object.keys(addressObject).length > 0) {
      addressObject.country = "India"; 
    }

    const params = {
      subAdminId: initialValues._id,
      firstName: data.firstName,
      lastName: data.lastName,
      userName: initialValues?.userName,
      password: data.password,
      isBlocked: initialValues.isBlocked,
      roleId: initialValues.roleId._id,
      permissions: initialValues.roleId.permissions,
      userList: userList
    };

    if (Object.keys(addressObject).length > 0) {
      params.address = addressObject;
    }

    if (selectedManager) {
      params.parentId = selectedManager && selectedManager._id ? selectedManager._id : selectedManager;
    }

    if (selectedemployees.length > 0) {
      params.userList = selectedemployees.map(emp => emp._id);
    }

    try {
      const { status } = await Axios.patch(API.SUB_ADMIN.UPDATE, params);
      if (status) {
        navigate(typeToRoute[data.type].update);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const [newcityOptions, setNewCityOptions] = useState([]);

  useEffect(() => {

    if (isUpdate) {
      if(initialValues.address.state){
        setStatesCode(initialValues.address.stateCode)
        setStateLabel(initialValues.address.state)
      }
    }
  }, [isUpdate, initialValues]);

  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  // const handleStateChange = (event) => {
  //   const [state, name] = event.target.value.split("_");
  //   console.log(state)
  //   setSelectedState(City.getCitiesOfState("IN", state));
  //   setState2(name)
  //   console.log(name)
  // };

  const handleStateChange = (event, flag) => {
    const [state, name] = flag ? event.split("_") : event.target.value.split("_");
    console.log(state)
    setSelectedState(City.getCitiesOfState("IN", state));
    setState2(name)
    setUpdatecity(selectedState)
    console.log(updatecity)
    console.log(name)
    console.log(selectedState)

  };


  const stateRef = useRef({ test: "hh" });
  const cityRef = useRef({ test: "hh" });

  const fetchManagers = async (states, cities) => {
    if (stateRef?.current && Array.isArray(stateRef.current) && stateRef.current.length > 0 && cityRef?.current && Array.isArray(cityRef.current) && cityRef.current.length > 0) {

      const formattedStates = stateRef?.current?.map(state => {
        return state.split('_')[1]?.replace(/\+/g, ' ');
      });

      const filter = {
        states: formattedStates,
        cities: cityRef?.current
      };

      console.log(stateRef.current)
      console.log(cityRef.current)

      try {
        console.error("Sucess fetching managers:");

        const { status, data } = await Axios.get(API.MANAGER.STATES, {
          ...filter,
        });

        if (status) {
          setNewEmployees(() => data.data);
          console.log(data.data)
          handleManagerList(data.data)
        }
      } catch (error) {
        console.error("Error fetching managers:", error);
      }

    }
  };

  const typeToRoute = {
    SUPERADMIN: {
      create: EndPoints.ADD_SUPER_ADMIN,
      update: EndPoints.EDIT_SUPER_ADMIN,
    },
    ADMIN: {
      create: EndPoints.ADD_ADMIN,
      update: EndPoints.EDIT_ADMIN,
    },
    SUBADMIN: {
      create: EndPoints.ADD_SUB_ADMIN,
      update: EndPoints.EDIT_SUB_ADMIN,
    },
    MANAGER: {
      create: EndPoints.ADD_MANAGER,
      update: EndPoints.EDIT_MANAGER,
    },
    SUPERVISOR: {
      create: EndPoints.ADD_SUPERVISOR,
      update: EndPoints.EDIT_SUPERVISOR,
    },
    AGENT: {
      create: EndPoints.ADD_AGENT,
      update: EndPoints.EDIT_AGENT,
    },
  };


  const updateCityList = () => {
    if (stateRef?.current && Array.isArray(stateRef.current) && stateRef.current.length > 0) {
      const data = [];
      for (let val of stateRef.current) {
        let stateCode = val.split("_")[0];
        const cities = City.getCitiesOfState("IN", stateCode);
        data.push(...cities);
      }
      setCityOptions(data)

    }
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={SuperAdminSchema}
      onSubmit={(values, { resetForm }) => {
        const { secondstate, ...filteredValues } = values
        isUpdate ? handleUpdateDocument(filteredValues) : handleSubmit(filteredValues);
        // resetForm();
      }}
    >
      {({ isSubmitting, errors, touched, setFieldValue, values }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Type</label>
                <Field as="select" className="form-control" name="type" onChange={(event) => {
                  const valueType = event.target.value;
                  const route = typeToRoute[valueType];
                  if (isUpdate) {
                    navigate(route.update);
                  } else {
                    navigate(route.create);
                  }
                }}>
                  <option value="SUBADMIN" label="AREA HEAD/SUBADMIN" />
                  <option value="SUPERADMIN" label="ROOT USER/SUPERADMIN" />
                  <option value="ADMIN" label="REGIONAL HEAD/ADMIN" />
                  <option value="MANAGER" label="MANAGER" />
                  <option value="SUPERVISOR" label="SUPERVISOR" />
                  <option value="AGENT" label="AGENT" />
                </Field>
                {errors.type && touched.type ? (
                  <div className="error">{errors.type}</div>
                ) : null}
              </div>

              {/* <div className="col-md-4 mb-3">
                <label>Name</label>
                <Field className="form-control" type="text" name="name" />
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div> */}

              <div className="col-md-4 mb-3">
                <label>First Name</label>
                <Field className="form-control" type="text" name="firstName" />
                {errors.firstName && touched.firstName ? (
                  <div className="error">{errors.firstName}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>Last Name</label>
                <Field className="form-control" type="text" name="lastName" />
                {errors.lastName && touched.lastName ? (
                  <div className="error">{errors.lastName}</div>
                ) : null}
              </div>


              <div className="col-md-4 mb-3">
                <label>UserId</label>
                <Field
                  className="form-control"
                  type="text"
                  name="userName"
                  disabled={isUpdate}
                  validate={validateUserName}

                />
                {errors.userName && touched.userName ? (
                  <div className="error">{errors.userName}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field className="form-control" type="text" name="password" />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>


              <div className="col-md-4 mb-3">
                  <label htmlFor="state">State</label>
                  <Field
                    as="select"
                    className="form-control"
                    name="state"
                    value={statesCode}

                    onChange={e => {
                      const selectedStateCode = e.target.value;

                      const selectedState = statesAndNewUTs.find(item => item.value === selectedStateCode);

                      if (selectedState) {
                        setStateLabel(selectedState.label);
                        setStatesCode(selectedState.value)
                        getCities(selectedStateCode);
                      }
                    }}
                  >
                    <option value="" label="Select state" />
                    <option value={isUpdate ? initialValues?.address?.state : ""} label={isUpdate ? initialValues?.address?.state : "Select state"} />
                    {statesAndNewUTs.map((state, index) => (
                      <option key={index} value={state.value}>
                        {state.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="state" component="div" className="error" />
                </div>




                <div className="col-md-4 mb-3">
                  <label>City</label>
                  <Field as="select" name="city" className="form-control">
                  <option value={isUpdate ? initialValues?.address?.city : ""} label={isUpdate ? initialValues?.address?.city :"Select a city"}></option>
                  {newcityOptions.map((city, index) => (
                      <option key={index} value={city.label}>
                        {city.label}
                      </option>
                    ))}
                  </Field>
                  <ErrorMessage name="city" component="div" className="error" />
                </div>

              {accountType !== 2 && accountType !== 3 ? (
                <div className="col-md-4 mb-3">
                  <label htmlFor="manager">Reporting Manager</label>
                  <Field as="select" className="form-control" name="manager" onChange={handleManagerChange}>
                    <option value={initialValues?.parentId?.name ?? ""} label={initialValues?.parentId?.name ?? "Select manager"} />
                    {managers && [...managers].map((manager) => (
                      <option key={manager._id} value={manager._id}>
                        {manager.name}
                      </option>
                    ))}
                  </Field>
                  {errors.manager && touched.manager ? (
                    <div className="error">{errors.manager}</div>
                  ) : null}
                </div>
              ) : (
                <div className="col-md-4 mb-3">
                  <option value={initialValues?.parentId?.name ?? ""} label={initialValues?.parentId?.name ?? "Select manager"} />
                  <Field as="select" className="form-control" name="manager" onChange={handleManagerChange1}>
                    <option value="" label="Select manager" />
                    <option key={managers[0]?._id} value={managers[0]?._id}>
                      {managers[0]?.name}
                    </option>
                  </Field>
                  {errors.manager && touched.manager ? (
                    <div className="error">{errors.manager}</div>
                  ) : null}
                </div>
              )}
              {/* <div className="col-md-4 mb-3">
                <label>City</label>
                <Field as="select" name="city" className="form-control">
                  <option value={initialValues?.city ?? ""} label={initialValues?.city ?? "Select City"}>Select a city</option>
                  {selectedState && selectedState.map((city, index) => (
                    <option key={index} value={city.name}>
                      {city.name}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="city" component="div" className="error" />


              </div> */}


              <hr style={{ backgroundColor: "white", height: '6px', width: '1500px' }}></hr>

              <div className="page-from">
                <h3 className="form-heading">Select Managers</h3>

                <div className="inner-form">
                  <div className="row">
                    <div className="col-md-4 mb-3">
                      <label htmlFor="secondstate">States</label>
                      <Field
                        name="secondstate"
                        options={statesAndUTs?.map((state) => ({
                          label: state.name,
                          value: state.isoCode + "_" + state.name,
                        }))}
                        component={CustomSelect}
                        stateRef={stateRef}
                        placeholder="Select States"
                        isMulti={true}
                        //onChange={(e) => handleStateChange(e)}
                        onChange={(selectedStates) => {
                          const statesArray = selectedStates.map(state => state.value);
                          setFieldValue("state", statesArray);
                          fetchManagers(statesArray, []);
                        }}

                      />
                      <ErrorMessage name="state" component="div" className="error" />
                    </div>


                    <div className="col-md-4 mb-3" onClick={updateCityList}>
                      <label>City</label>
                      <Field
                        name="city2"
                        options={cityOptions.map((city) => ({
                          label: city.name,
                          value: city.name,
                        }))}
                        component={CustomSelect}
                        cityRef={cityRef}
                        placeholder="Select Cities"
                        isMulti={true}
                        onChange={(selectedCities) => {
                          const citiesArray = selectedCities.map(city => city.value);
                          setFieldValue("city", citiesArray);
                          fetchManagers([], citiesArray);
                        }}
                      />
                      <ErrorMessage name="city" component="div" className="error" />
                    </div>


                    <div className="col-md-4 mb-3" onClick={fetchManagers}>
                      <label htmlFor="userList">Select Managers</label>
                      <Field
                        name="userList"
                        options={manager}
                        component={CustomSelect}
                        placeholder="Select Managers..."
                        isMulti={true}
                      />
                    </div>

                    {/* {!isUpdate && (
                      <FieldArray name="permissions">
                        {() => (
                          <table className="table table-bordered text-center">
                            <thead className="bg-light text-dark">
                              <tr>
                                <th scope="col">Permissions</th>
                                <th scope="col">All</th>
                                <th scope="col">View</th>
                                <th scope="col">Add</th>

                              </tr>
                            </thead>
                            <tbody>
                              {values?.permissions?.map((val, index) => (
                                <tr key={index}>
                                  <td>{val.name}</td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`[${index}].all`}
                                      checked={val.all}
                                      onChange={(e) => {
                                        const check = !(values?.permissions[index].all);
                                        setFieldValue(`permissions[${index}].all`, check);
                                        setFieldValue(`permissions[${index}].view`, check);
                                        setFieldValue(`permissions[${index}].add`, check);

                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`[${index}].view`}
                                      checked={val.view ?? val.all}
                                      onChange={(e) => {
                                        const check = !(values?.permissions[index].view);
                                        setFieldValue(`permissions[${index}].view`, check);

                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`[${index}].add`}
                                      checked={val.add ?? val.all}
                                      onChange={(e) => {
                                        const check = !(values?.permissions[index].add);
                                        setFieldValue(`permissions[${index}].add`, check);

                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </FieldArray>
                    )} */}
                    {!isUpdate && (
                      <FieldArray name="permissions">
                        {() => (
                          <table className="table table-bordered text-center">
                            <thead className="bg-light text-dark">
                              <tr>
                                <th scope="col">Permissions</th>
                                <th scope="col">All</th>
                                <th scope="col">View</th>
                                <th scope="col">Add</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values?.permissions?.map((val, index) => (
                                <tr key={index}>
                                  <td>{val.name}</td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`permissions[${index}].all`}
                                      checked={val.all}
                                      onChange={(e) => {
                                        const check = !values?.permissions[index].all;
                                        setFieldValue(`permissions[${index}].all`, check);
                                        setFieldValue(`permissions[${index}].view`, check);
                                        setFieldValue(`permissions[${index}].add`, check);
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`permissions[${index}].view`}
                                      checked={val.view ?? val.all}
                                      onChange={(e) => {
                                        const check = !values?.permissions[index].view;
                                        setFieldValue(`permissions[${index}].view`, check);
                                        if (!check) {
                                          setFieldValue(`permissions[${index}].all`, false);
                                        }
                                      }}
                                    />
                                  </td>
                                  <td>
                                    <Field
                                      className="form-check-input"
                                      type="checkbox"
                                      name={`permissions[${index}].add`}
                                      checked={val.add ?? val.all}
                                      onChange={(e) => {
                                        const check = !values?.permissions[index].add;
                                        setFieldValue(`permissions[${index}].add`, check);

                                        // If 'add' is unchecked, uncheck 'all'
                                        if (!check) {
                                          setFieldValue(`permissions[${index}].all`, false);
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        )}
                      </FieldArray>
                    )}



                    <div className="col-md-12 mb-3">
                      <button
                        //disabled={isSubmitting}
                        className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                        type="submit"
                      >
                        {isUpdate ? UPDATE : CREATE}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default SubAdmin;
