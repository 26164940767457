import React, { useState, useEffect, useCallback } from "react";
import { Formik, Form, Field , ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { ManagerSchema } from "../../Helpers/Constants/Schemas";
import { ManagerFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { useSelector } from 'react-redux';

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CREATE, UPDATE, USER_IDS } from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { toast } from "react-toastify";
import { LIMIT1, NA, OFFSET } from "../../Helpers/Constants/Default";

function generateSerialNumber() {
  return String(Math.floor(Math.random() * 999) + 1).padStart(3, "0");
}

const Manager = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [randomSerialNumber, setRandomSerialNumber] = useState("");
  const [stateLabel, setStateLabel] = useState('');
  const [statesCode, setStatesCode] = useState('');

  useEffect(() => {
    const generateSerialNumber = () => {
      return String(Math.floor(Math.random() * 999) + 1).padStart(3, "0");
    };
    setRandomSerialNumber(generateSerialNumber());
  }, []);

  const [isUpdate] = useState(state ? true : false);
  const [addFetchPincode, setAddFetchPincode] = useState(true);
  const [initialValues, setInitialValue] = useState(
    isUpdate ? state : ManagerFields.required
  );
  const [dropdown, setDropdown] = useState({
    countries: [],
    states: [],
    cities: [],
  });

  const paginate = useSelector((val) => val.paginate);

  const [data, setData] = useState([]);
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT1,
    offset: paginate?.subAdmin || OFFSET,
  });

  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);

  const [accountType, setAccountType] = useState(null);



  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status: subAdminStatus, data: subAdminData } = await Axios.get(API.SUB_ADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });

      const { status: parentStatus, data: parentData } = await Axios.get(API.ADMIN.PARENT);

      let combinedManagers = [];
      if (subAdminStatus === true) {
        combinedManagers = subAdminData.data || [];
      }

      if (parentStatus === true) {
        combinedManagers = [
          ...combinedManagers,
          { _id: parentData._id, name: parentData.name }
        ];
      }

      setManagers(combinedManagers);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };




  const getDataSuper = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status: subAdminStatus, data: subAdminData } = await Axios.get(API.SUB_ADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });

      const { status: adminStatus, data: adminData } = await Axios.get(API.ADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });

      const { status: superAdminStatus, data: superAdminData } = await Axios.get(API.SUPERADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });

      let combinedManagers = [];

      if (subAdminStatus === true) {
        combinedManagers = subAdminData.data || [];
      }

      if (adminStatus === true) {
        combinedManagers = [
          ...combinedManagers,
          ...(adminData.data || []),
        ];
      }

      if (superAdminStatus === true) {
        combinedManagers = [
          ...combinedManagers,
          ...(superAdminData.data || []),
        ];
      }

      setManagers(combinedManagers);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDataSure = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.SUB_ADMIN.PARENT, {

      });
      console.log(data.managers)
      console.log(data)
      console.log(status)
      if (status === true) {
        setData(data);
        setManagers([data] || []);
        console.log(data.name)
        console.log(data.accountType)
        setAccountType(data.accountType);

        console.log(managers)
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    console.log("Filter changed:", filter);
    getDataSure();
  }, [filter]);

  useEffect(() => {
    console.log("Filter changed:", filter);
    getData();
  }, [filter]);


  const getDataCallback = useCallback(() => {
    if (accountType == 1) {
      getDataSuper();
    }
  }, [accountType, getDataSuper]);
  useEffect(() => {
    console.log("Filter changed:", filter);
    getDataCallback();
  }, [filter, accountType]);

  const handleManagerChange1 = (event) => {
    const selectedManagerObject = event.target.value;
    console.log(event.target.value)
    setSelectedManager(selectedManagerObject);
  };




  // const handleSubmit = async (data, setSubmitting) => {
  //   delete data.name

  //   const { state, city,pincode,address, ...rest } = data;
  //   const selectedCity = cityOptions.find(item => item.label === city);
  //   const payload = {
  //     ...rest,
  //     address: {
  //       state: stateLabel,
  //       pincode:address.pincode,
  //       address:address.address,
  //       stateCode: statesCode,
  //       city: selectedCity ? selectedCity.label : city,
  //       country: "India",
  //     },
  //   };
  //   try {
  //     delete data.email;
  //     delete data.name;
  //     delete data.phoneNumber;
 
      
  //     data.address.stateCode = initialValues.address.stateCode;
  //     if (selectedManager) {
  //       data.parentId = selectedManager && selectedManager?._id ? selectedManager?._id : selectedManager;
  //     }

  //     console.log(selectedManager)
  //     console.log(data)
      
  //     const { status } = await Axios.post(API.MANAGER.CREATE, payload);
  //     if (status) navigate(EndPoints.MANAGERS);
  //   } catch (err) {
  //     console.log("ERROR==>", err);
  //     setSubmitting(false);
  //   }
  // };


  const handleSubmit = async (data, setSubmitting) => {
    delete data.name;
  
    const { state, city, pincode, address, ...rest } = data;
    const selectedCity = cityOptions.find(item => item.label === city);
  
    const payload = {
      ...rest,
      address: {
        state: stateLabel,
        pincode: address.pincode,
        address: address.address,
        stateCode: statesCode,
        city: selectedCity ? selectedCity.label : city,
        country: "India",
      },
      parentId: selectedManager?._id ? selectedManager._id : selectedManager,
    };
  
    try {
      delete data.email;
      delete data.name;
      delete data.phoneNumber;
  
      const { status } = await Axios.post(API.MANAGER.CREATE, payload);
      if (status) navigate(EndPoints.MANAGERS);
    } catch (err) {
      console.log("ERROR==>", err);
      setSubmitting(false);
    }
  };
  

  const fetchCountries = async () => {
    try {
      const { data, status } = await Axios.get(API.CSC.COUNTRIES);
      if (status) setDropdown((prev) => ({ ...prev, countries: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchStates = async (parentId) => {
    try {
      const { data, status } = await Axios.get(API.CSC.STATES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, states: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchCities = async (e, setFieldValue, values) => {
    try {
      const parentId =
        e.target?.options[e.target?.selectedIndex]?.dataset?.parentid,
        stateCode =
          e.target?.options[e.target?.selectedIndex]?.dataset?.statecode;

      setInitialValue((prev) => ({
        ...prev,
        address: { ...prev.address, stateCode },
      }));

      if (!isUpdate) {
        const userName = generateUserId(values.name, stateCode);
        setFieldValue("userName", userName);
      }

      const { data, status } = await Axios.get(API.CSC.CITIES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, cities: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeAddress = async (pincode, setFieldValue, values) => {
    try {
      let stateCode = "";

      setFieldValue("address.pincode", pincode);

      if (pincode.length === 6) {
        const data = await Axios.get(API.COMMON.ADDRESS_API, {
          pincode,
        });

        if (data?.status) {
          setAddFetchPincode(true);

          setFieldValue("address.country", data?.data?.country || "");
          setFieldValue("address.state", data?.data?.state || "");
          setFieldValue("address.city", data?.data?.city || "");
          setFieldValue("address.address", data?.data?.formatted_address || "");

          stateCode = data?.data?.state_code || "";

          setInitialValue((prev) => ({
            ...prev,
            address: { ...prev.address, stateCode },
          }));
        } else {
          setAddFetchPincode(false);

          dropdown.countries.length || fetchCountries();

          setFieldValue("address.country", "");
          setFieldValue("address.state", "");
          setFieldValue("address.city", "");
          setFieldValue("address.address", "");
        }

        if (!isUpdate) {
          // const userName = generateUserId(values.name, stateCode);
          // setFieldValue("userName", userName);
        }
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeUserId = async (firstName, setFieldValue) => {
    try {
      setFieldValue("firstName", firstName);
      const userName = generateUserId(firstName, initialValues.address?.stateCode);
      const userEmail = generateEmailId(firstName);
      setFieldValue("userName", userName);
      setFieldValue("email", userEmail);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const generateEmailId = (name) => {
    let userEmail = name?.toLowerCase()?.replace(/\s/g, "");
    if (userEmail) {
      userEmail = `${userEmail}${randomSerialNumber}@gmail.com`;
    }

    return userEmail;
  };

  const generateUserId = (name, stateCode) => {
    let userName = name?.toLowerCase()?.replace(/\s/g, "");

    if (userName) {
      userName = `${userName}${randomSerialNumber}`;
      userName = stateCode
        ? `${userName}.${stateCode}.${USER_IDS.MANAGER}`
        : userName;
    }

    return userName;
  };

  const handleUpdateDocument = async (data, setSubmitting) => {
    try {

      delete data.userName;
      delete data.phoneNumber;
      delete data.name;

      

      const { state, city, stateCode,pincode,address, userList } = data;

      console.log(data)
  
      const addressObject = {};
      if (stateLabel) {
        addressObject.state = stateLabel;
      }
      if (statesCode) {
        addressObject.stateCode = statesCode;
      }
      if (address.pincode) {
        addressObject.pincode = address.pincode;
      }
      if (address.address) {
        addressObject.address = address.address;
      }

      if (city) {
        const selectedCity = cityOptions.find(item => item.label === city);
        addressObject.city = selectedCity ? selectedCity.label : city;
        console.log("City added to addressObject:", addressObject.city);  
      } else {
        addressObject.city = initialValues?.address?.city
        console.log("No city selected."); 
      }
  
      if (Object.keys(addressObject).length > 0) {
        addressObject.country = "India";
      }
      const params = await UpdateParam(ManagerFields, data, []);
      if (Object.keys(addressObject).length > 0) {
        params.address = addressObject;
      }
  
      params.userList = userList;
      if (selectedManager) {
        data.parentId = selectedManager && selectedManager._id ? selectedManager._id : selectedManager;
      }
      const { status } = await Axios.patch(API.MANAGER.UPDATE, {
        managerId: initialValues._id,
        parentId: initialValues.parentId,
        firstName: data.firstName,
        lastName: data.lastName,

        ...params,
      });

      if (status) navigate(EndPoints.MANAGERS);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSubmitting(false);
    }
  };

  const handlePassword = (value, setFieldValue) => {
    if (value.length > 4) {
      toast.error("Password Pin must be only 4 digits", {
        position: "bottom-center",
      });
    } else {
      setFieldValue("password", value);
    }
  };

  const handleManagerChange = (event) => {
    const selectedValue = event.target.value;
    const selectedManagerObject = managers.find(manager => manager._id === selectedValue);
    setSelectedManager(selectedManagerObject);
  };

  const [statesAndUTs, setStatesAndUTs] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);


  const getStates = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.LOCATION.STATES);
      if (status) {
        setStatesAndUTs(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  const getCities = async (stateCode) => {
    if (!stateCode) {
      console.log("State code is required to fetch cities.");
      return;
    }

    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(`${API.LOCATION.CITY}?stateCode=${stateCode}`);
      if (status) {
        setCityOptions(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };



  useEffect(() => {
    getStates();
  }, [filter]);


  useEffect(() => {

    if (isUpdate) {
      if(initialValues.address.state){
        setStatesCode(initialValues.address.stateCode)
        setStateLabel(initialValues.address.state)
      }
    }
  }, [isUpdate, initialValues]);
  return (
    <Formik
      initialValues={initialValues}
      //validationSchema={ManagerSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        isUpdate
          ? handleUpdateDocument(values, setSubmitting)
          : handleSubmit(values, setSubmitting);
        // resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        setFieldValue,
        values,
        handleChange,
      }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>First Name</label>
                <Field
                  className="form-control"
                  type="text"
                  name="firstName"
                  onChange={(e) =>
                    handleChangeUserId(e?.target?.value, setFieldValue)
                  }
                  disabled={isUpdate}
                />
                {errors.firstName && touched.firstName ? (
                  <div className="error">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>Last Name</label>
                <Field className="form-control" type="text" name="lastName" />
                {errors.lastName && touched.lastName ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>


              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field
                  className="form-control"
                  type="number"
                  name="password"
                  onChange={(e) =>
                    handlePassword(e?.target?.value, setFieldValue)
                  }
                />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>

              {/* <div className="col-md-4 mb-3">
                <label>Mobile Number</label>
                <Field
                  className="form-control"
                  type="text"
                  name="phoneNumber"
                />
                {errors.phoneNumber && touched.phoneNumber ? (
                  <div className="error">{errors.phoneNumber}</div>
                ) : null}
              </div> */}

              <div className="col-md-4 mb-3">
                <label>User Id</label>
                <Field
                  className="form-control"
                  type="text"
                  name="userName"
                  disabled={true}
                />
                {errors.userName && touched.userName ? (
                  <div className="error">{errors.userName}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label htmlFor="manager">Reporting Manager</label>

                {accountType !== 3 ? (
                  accountType === 1 ? (
                    <Field as="select" className="form-control" name="manager" onChange={handleManagerChange}>
                      <option value="" label="Select manager" />
                      {managers.map((manager) => (
                        <option key={manager._id} value={manager._id}>
                          {manager.name}
                        </option>
                      ))}
                    </Field>
                  ) : (
                    <Field as="select" className="form-control" name="manager" onChange={handleManagerChange}>
                      <option value="" label="Select manager" />
                      {managers.map((manager) => (
                        <option key={manager._id} value={manager._id}>
                          {manager.name}
                        </option>
                      ))}
                    </Field>
                  )
                ) : (
                  <Field as="select" className="form-control" name="manager" onChange={handleManagerChange1}>
                    <option value="" label="Select manager" />
                    <option key={managers[0]?._id} value={managers[0]?._id}>
                      {managers[0]?.name}
                    </option>
                  </Field>
                )}

                {/* Validation errors */}
                {errors.manager && touched.manager ? (
                  <div className="error">{errors.manager}</div>
                ) : null}
              </div>




              <div className="col-md-12 mb-3">
                <h3>Address</h3>
                <div className="row">
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="address.pincode">Pincode</label>
                      <Field
                        type="number"
                        className="form-control"
                        name="address.pincode"
                        // onChange={(e) =>
                        //   handleChangeAddress(
                        //     e?.target?.value,
                        //     setFieldValue,
                        //     values
                        //   )
                        // }
                      />
                      {errors.address?.pincode && touched.address?.pincode ? (
                        <div className="error">{errors.address?.pincode}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="address.address">Address</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="address.address"
                      />
                      {errors.address?.address && touched.address?.address ? (
                        <div className="error">{errors.address?.address}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-md-4">
                    <div className="form-group">
                      <label htmlFor="address.country">Country</label>

                      {addFetchPincode ? (
                        <Field
                          className="form-control"
                          type="text"
                          name="address.country"
                        // disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.country"
                          onChange={(e) => {
                            handleChange(e);
                            fetchStates(
                              e.target?.options[e.target?.selectedIndex]
                                ?.dataset?.parentid,
                              setFieldValue,
                              values
                            );
                          }}
                        >
                          <option value="">Click to Select</option>
                          {dropdown.countries?.map((country) => (
                            <option
                              key={country._id}
                              value={country.name}
                              data-parentid={country._id}
                            >
                              {country.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.country && touched.address?.country ? (
                        <div className="error">{errors.address?.country}</div>
                      ) : null}
                    </div>
                  </div> */}
                  {/* <div className="col-md-4 mt-3">
                    <div className="form-group">
                      <label htmlFor="address.state">State</label>

                      {addFetchPincode ? (
                        <Field
                          type="text"
                          className="form-control"
                          name="address.state"
                        // disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.state"
                          onChange={(e) => {
                            handleChange(e);
                            fetchCities(e, setFieldValue, values);
                          }}
                        >
                          <option value="">Click to Select</option>
                          {dropdown.states?.map((state) => (
                            <option
                              key={state._id}
                              value={state.name}
                              data-parentid={state._id}
                              data-statecode={state.code}
                            >
                              {state.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.state && touched.address?.state ? (
                        <div className="error">{errors.address?.state}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-4 mt-3">
                    <div className="form-group">
                      <label htmlFor="address.city">City</label>

                      {addFetchPincode ? (
                        <Field
                          type="text"
                          className="form-control"
                          name="address.city"
                        // disabled={true}
                        />
                      ) : (
                        <Field
                          className="form-control"
                          as="select"
                          name="address.city"
                        >
                          <option value="">Click to Select</option>
                          {dropdown.cities?.map((city) => (
                            <option key={city._id} value={city.name}>
                              {city.name}
                            </option>
                          ))}
                        </Field>
                      )}

                      {errors.address?.city && touched.address?.city ? (
                        <div className="error">{errors.address?.city}</div>
                      ) : null}
                    </div>
                  </div> */}
                        <div className="col-md-4 mb-3">
                    <label htmlFor="state">State</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="state"
                      onChange={e => {
                        const selectedStateCode = e.target.value;

                        const selectedState = statesAndUTs.find(item => item.value === selectedStateCode);

                        if (selectedState) {
                          setStateLabel(selectedState.label);
                          setStatesCode(selectedState.value)
                          getCities(selectedStateCode);
                          const userName = generateUserId(values.firstName, selectedStateCode);
                          console.log(values)
                          console.log(userName)
                          setFieldValue("userName", userName);
                        }
                      }}
                    >
                    <option value={isUpdate ? initialValues?.address?.state : ""} label={isUpdate ? initialValues?.address?.state : "Select state"} />
                    {statesAndUTs.map((state, index) => (
                        <option key={index} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="state" component="div" className="error" />
                  </div>
                  <div className="col-md-4 mb-3">
                    <label>City</label>
                    <Field as="select" name="city" className="form-control">
                    <option value={isUpdate ? initialValues?.address?.city : ""} label={isUpdate ? initialValues?.address?.city :"Select a city"}></option>
                    {cityOptions.map((city, index) => (
                        <option key={index} value={city.label}>
                          {city.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="city" component="div" className="error" />
                  </div>
                </div>
              </div>

              <div className="col-md-12 mb-3 mt-3">
                <button
                  //disabled={isSubmitting}
                  className="btn bg-gradient-dark d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Manager;
