import * as Yup from "yup";

// export const LoginSchema = Yup.object({
//   email: Yup.string().email().required("Required"),
//   password: Yup.string().required("Required"),
// });
export const LoginSchema = Yup.object({
  userName: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export const ForgotPasswordSchema = Yup.object({
  email: Yup.string().email().required("Required"),
});

export const ResetPasswordSchema = Yup.object({
  password: Yup.string().required("Required"),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref("password"), null], "Passwords must match")
    .required("Required"),
});

export const ManagerSchema = Yup.object({
  //name: Yup.string().required("Required"),
  // email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
  // phoneNumber: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  address: Yup.object().shape({
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    // .min(100000, "Pincode must be at least 6 digits")
    // .max(999999, "Pincode cannot exceed 6 digits"),
    country: Yup.string().required("Required"),
  }),
});

export const AgentSchema = Yup.object({
  //name: Yup.string().required("Required"),
  // email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
  // phoneNumber: Yup.string().required("Required"),
  parentId: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  address: Yup.object().shape({
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    // .min(100000, "Pincode must be at least 6 digits")
    // .max(999999, "Pincode cannot exceed 6 digits"),
    country: Yup.string().required("Required"),
  }),
});

export const SupervisorSchema = Yup.object({
  //name: Yup.string().required("Required"),
  //firstName: Yup.string().required("Required"),

  // email: Yup.string().email().required("Required"),
  password: Yup.string().required("Required"),
  // phoneNumber: Yup.string().required("Required"),
  parentId: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  address: Yup.object().shape({
    state: Yup.string().required("Required"),
    city: Yup.string().required("Required"),
    // .min(100000, "Pincode must be at least 6 digits")
    // .max(999999, "Pincode cannot exceed 6 digits"),
    country: Yup.string().required("Required"),
  }),
});

export const SubAdminSchema = Yup.object({
  name: Yup.string().required("Required"),
  // email: Yup.string().email().required("Required"),
  userName: Yup.string().required("Required"),
  password: Yup.string().required("Required"),
});

export const SuperAdminSchema = Yup.object({
  //name: Yup.string().required("Required"),
  userName: Yup.string().required("Required"),
  firstName:Yup.string().required("Required"),
  
  password: Yup.string().required("Required"),
});


export const SendNotifySchema = Yup.object({
  title: Yup.string()
    .transform((value) => value.trim())
    .required("Required"),
  body: Yup.string()
    .transform((value) => value.trim())
    .required("Required"),
});

export const CMSSchema = Yup.object({
  title: Yup.string().required("Required"),
  description: Yup.string().required("Required"),
});

export const TrasnferAccSchema = Yup.object({
  transferToId: Yup.string().required("Required"),
});

export const ConfigSchema = Yup.object({
  value: Yup.string().required("Required"),
});
