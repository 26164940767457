import React, { Fragment, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Switch from "react-switch";

import Loader from "../Common/Loader";
import Paginate from "../Common/Paginate";

import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  BLOCK_STATUS,
  LIMIT,
  NA,
  OFFSET,
  USER_TYPE,
} from "../../Helpers/Constants/Default";
import DatePicker from "../Common/DatePicker";
import TransferAcc from "../Common/TransferAcc";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import DataNotFound from "../Common/DataNotFound";
import { City, State } from "country-state-city";

const Supervisors = ({ permissions: { all, add, edit, delete: del } }) => {
  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState([]);
  const [dropdown, setDropdown] = useState({ managers: [] });
  const [filter, setFilter] = useState({
    limit: LIMIT,
    offset: paginate?.supervisor || OFFSET,
  });
  const [visible, setVisible] = useState({ loader: false, transferAcc: false });
  const [transferAccData, setTransferAccData] = useState({
    fromUserName: "",
    toUserName: "",
    fromId: "",
  });

  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.SUPERVISOR.LISTING, filter);
      if (status) setData(data);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleBlockAccess = async ({ _id, blockAccess }) => {
    try {
      const { status } = await Axios.patch(API.SUPERVISOR.UPDATE, {
        supervisorId: _id,
        blockAccess: !blockAccess,
      });
      if (status)
        setData((prev) => ({
          ...prev,
          data: prev?.data?.map((val) =>
            val._id === _id ? { ...val, blockAccess: !blockAccess } : val
          ),
        }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleResetPassword = async (supervisorId) => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      await Axios.get(API.SUPERVISOR.RESET_PASSWORD + "/" + supervisorId);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const getDropdowns = async () => {
    try {
      const { data, status } = await Axios.get(API.DROPDOWN.USER, {
        accountType: USER_TYPE.MANAGER,
      });

      if (status) setDropdown((prev) => ({ ...prev, managers: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const setFilters = (event, key) => {
    let value = event?.target?.value;
    if (key == "states") {
      value = [JSON.parse(value).name]
    }
    if (key == "cities") {
      value = [JSON.parse(value).name]
    }
    value
      ? setFilter((prev) => ({ ...prev, [key]: value }))
      : setFilter((prev) => {
        delete prev[key];
        return { ...prev };
      });
  };

  const exportExcel = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.REPORT.SUPERVISOR, filter);

      if (status) window.open(data, "_blank");
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  const handleAction = async (supervisorId) => {
    setData((prev) => ({
      ...prev,
      data: prev?.data?.map((val) =>
        val._id === supervisorId && !val?.action
          ? { ...val, action: true }
          : { ...val, action: false }
      ),
    }));
  };

  const handleBlockUnblock = async ({ _id, isBlocked }) => {
    try {
      Swal.fire({
        title: `${isBlocked ? "Block" : "Un-block"} Supervisor`,
        text: `Are you sure you want to ${isBlocked ? "Block" : "Un-block"}?`,
        confirmButtonText: "Yes",
        showCancelButton: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const { status } = await Axios.patch(API.SUPERVISOR.UPDATE, {
            supervisorId: _id,
            isBlocked: !isBlocked,
          });
          if (status)
            setData((prev) => ({
              ...prev,
              data: prev?.data?.map((val) =>
                val._id === _id ? { ...val, isBlocked: !isBlocked } : val
              ),
            }));
        }
      });
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleBlur = () => {
    setData((prev) => ({
      ...prev,
      data: prev?.data?.map((val) => ({
        ...val,
        action: false,
      })),
    }));
  };

  const handleTransferAcc = (val) => {
    setTransferAccData((prev) => ({
      ...prev,
      fromUserName: val.userName,
      transferFromId: val._id,
      agentCount: val.agentCount,
    }));
    setVisible((prev) => ({ ...prev, transferAcc: true }));
  };

  useEffect(() => {
    getData();
  }, [filter]);

  useEffect(() => {
    getDropdowns();
  }, []);

  const [states, setStatesAndUTs] = useState([])
  const [cities, setSelectedState] = useState([])
  useEffect(() => {
    setStatesAndUTs(State.getStatesOfCountry("IN"))
  }, [])

  const handleStateChange = (event) => {
    let state = JSON.parse(event.target.value).isoCode;
    console.log("state", state)
    setSelectedState(City.getCitiesOfState("IN", state));
  };

  return (
    <Fragment>
      {visible.transferAcc && (
        <TransferAcc
          setVisible={setVisible}
          transferAccData={transferAccData}
          setTransferAccData={setTransferAccData}
          setData={setData}
        />
      )}
      {visible.loader && <Loader />}
      <div className="container-fluid py-3">
        <div className="dateselect_navigation d-flex justify-content-between align-items-center">
          <div className="d-flex main_bar">
            {/* <DatePicker filter={filter} setFilter={setFilter} /> */}
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "parentId")}
            >
              <option value="">Managers</option>
              {dropdown.managers?.map((val) => (
                <option key={val._id} value={val._id}>
                  {val.name}
                </option>
              ))}
            </select>
            <div className="d-flex position-relative bg-gradient-dark rounded">
              <i
                className="fa fa-search position-absolute top-50 translate-middle ms-3 text-white"
                aria-hidden="true"
              ></i>
              <input
                onChange={(e) => setFilters(e, "search")}
                type="search"
                className="text-start bg-gradient-dark p-2 btn text-lowercase border-0 text-white btn-lg mb-1 mx-4"
                style={{ cursor: "unset" }}
                name="gsearch"
              ></input>
            </div>
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => {
                handleStateChange(e);
                setFilters(e, "states")
              }}
            >
              <option value="">Select State</option>
              {states.map((val, i) => (
                <option key={i} value={JSON.stringify(val)}>
                  {val.name}
                </option>
              ))}
            </select>
            <select
              className="form-select text-start btn bg-gradient-dark btn-md mb-0 mx-2"
              onChange={(e) => setFilters(e, "cities")}
            >
              <option value="">Select City</option>
              {cities.map((val, i) => (
                <option key={i} value={JSON.stringify(val)}>
                  {val.name}
                </option>
              ))}
            </select>
          </div >
          <div className="d-flex align-items-center">
            <div className="me-2  mob-margin-none">
              {(all || add) && (
                <Link to={EndPoints.ADD_SUPERVISOR}>
                  <button className="btn bg-gradient-dark float-end d-flex btn-md mb-1 h-52 align-items-center">
                    <i className="fa fa-plus me-2 mt-1" aria-hidden="true"></i>
                    Add
                  </button>
                </Link>
              )}
            </div>
            <button
              className="btn bg-gradient-dark float-end d-flex btn-md mb-1 align-items-center h-52 data-del"
              onClick={exportExcel}
            >
              <i className="fa fa-download me-2 mt-1" aria-hidden="true"></i>
              Export
            </button>
          </div>
        </div >
        {data?.data?.length === 0 ? (
          <DataNotFound />
        ) : (
          <Fragment>
            <div className="overflow-table">
              <table className="page-table">
                <thead>
                  <tr>
                    <th scope="col" className="text-uppercase">
                      #
                    </th>
                    <th scope="col" className="text-uppercase">
                      Profile
                    </th>
                    <th scope="col" className="text-uppercase">
                      User Id
                    </th>
                    <th scope="col" className="text-uppercase">
                      Name
                    </th>
                    {/* <th scope="col" className="text-uppercase">
                      Phone Number
                    </th> */}
                    <th scope="col" className="text-uppercase">
                      Manager
                    </th>
                    <th scope="col" className="text-uppercase">
                      No. of Agents
                    </th>
                    <th scope="col" className="text-uppercase">
                      Transfer
                    </th>
                    <th scope="col" className="text-uppercase">
                      Blocked Access
                    </th>
                    {/* <th scope="col" className="text-uppercase">
                  Date
                </th> */}
                    {(all || edit || del) && (
                      <th scope="col" className="text-uppercase">
                        Action
                      </th>
                    )}
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((val, i) => (
                    <tr key={val._id}>
                      <td>{++i + filter.offset}</td>
                      <td>
                        {val.image ? (
                          <img
                            className="profile"
                            src={val.image}
                            alt={val.name}
                          />
                        ) : (
                          NA
                        )}
                      </td>
                      <td>
                        <Link to={EndPoints.SUPERVISOR} state={val._id}>
                          {val.userName}
                        </Link>
                      </td>
                      <td>{val.name}</td>
                      {/* <td>{val?.phoneNumber}</td> */}
                      <td>{val.parentId?.name || NA}</td>
                      <td>{val.agentCount}</td>
                      <td>
                        {val.transferAcc ? (
                          <i
                            className="fa fa-life-ring fs-6 text-danger pe-auto"
                            disabled={true}
                          ></i>
                        ) : (
                          <i
                            onClick={() => handleTransferAcc(val)}
                            className="fa fa-life-ring fs-6 text-success pe-auto"
                          ></i>
                        )}
                      </td>
                      <td>
                        <Switch
                          onChange={() => handleBlockAccess(val)}
                          checked={val.blockAccess}
                          onColor="#3e4396"
                          offColor="#141b2d"
                        />
                      </td>
                      {/* <td>{CreatedAt(val.createdAt)}</td> */}
                      {(all || edit || del) && (
                        // <td>
                        //   <Link
                        //     to={EndPoints.EDIT_SUPERVISOR}
                        //     state={{
                        //       ...val,
                        //       password: val.dcryptedPass,
                        //       parentId: val.parentId?._id,
                        //     }}
                        //   >
                        //     Edit
                        //   </Link>
                        // </td>
                        <td>
                          <div
                            className="position-relative"
                          // tabIndex={val._id}
                          // onBlur={handleBlur}
                          >
                            <i
                              className="material-icons ps-2 cursior-pointer"
                              onClick={() => handleAction(val._id)}
                            >
                              more_horiz
                            </i>
                            <div className="more-ic">
                              <ul
                                className="icon-list"
                                style={{
                                  display: val?.action ? "block" : "none",
                                }}
                              >
                                <li
                                  onClick={() => handleResetPassword(val._id)}
                                >
                                  Reset Pass
                                </li>
                                <li onClick={() => handleBlockUnblock(val)}>
                                  {val.isBlocked
                                    ? BLOCK_STATUS.BLOCKED
                                    : BLOCK_STATUS.UN_BLOCKED}
                                </li>
                                <li>
                                  <Link
                                    to={EndPoints.EDIT_SUPERVISOR}
                                    state={{
                                      ...val,
                                      password: val.dcryptedPass,
                                      parentId: val.parentId?._id,
                                    }}
                                  >
                                    Edit
                                  </Link>
                                </li>
                              </ul>
                            </div>
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <Paginate
              count={data?.count}
              filter={filter}
              setFilter={setFilter}
              sliceKey={"supervisor"}
              forcePage={paginate?.supervisor / 10}
            />
          </Fragment>
        )}
      </div >
    </Fragment >
  );
};

export default Supervisors;
