import { configureStore } from "@reduxjs/toolkit";
import rootReducer from "../reducer";
import { persistStore, persistReducer, createTransform } from 'redux-persist';
// import storage from 'redux-persist/lib/storage'
import storageSession from 'redux-persist/lib/storage/session';

import CryptoJS from 'crypto-js';

const encryptTransform = createTransform(
    (inboundState) => {
        return CryptoJS.AES.encrypt(JSON.stringify(inboundState), 'prashant-tracks').toString();
    },
    (outboundState) => {
        const bytes = CryptoJS.AES.decrypt(outboundState, 'prashant-tracks');
        return JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    }
);


const persistConfig = {
    key: 'root',
    storage: storageSession,
    transforms: [encryptTransform]
};
const persistedReducer = persistReducer(persistConfig, rootReducer);
const store = configureStore({
    reducer: persistedReducer,
});
const persistor = persistStore(store);

export { store, persistor };