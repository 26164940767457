import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import logo from "../../assests/logo.png";
import { LoginFileds } from "../../Helpers/Constants/InitialValues";
import { LoginSchema } from "../../Helpers/Constants/Schemas";

import Loader from "../Common/Loader";

import API from "../../Helpers/Constants/EndPoints/Api";

import Axios from "../../Helpers/Universal/Axios/Axios";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import { getProfile } from "../../Helpers/Universal/Axios/Operation";
import { useDispatch } from "react-redux";
import { setProfile } from "../../slices/profileSlice";
import { setAccType } from "../../slices/accTypeSlice";

const Login = () => {
  const [visible, setVisible] = useState(false);
  const [passVisible, setPassVisible] = useState(false);
  const [initialValues] = useState(LoginFileds);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (params) => {
    console.log(params)
    try {
      setVisible(true);

      const { status, data } = await Axios.post(API.LOGIN.ADMIN, params);

      if (status) {
        localStorage.setItem("token", data.token);
        dispatch(setAccType(data.accountType));
        dispatch(setProfile(await getProfile()));
        navigate(EndPoints.DASHBOARD);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible(false);
    }
  };

  useEffect(() => {
    if (localStorage.getItem("token")) navigate(EndPoints.DASHBOARD);
    // localStorage.clear();
  }, []);

  return (
    <>
      {visible && <Loader />}
      <Formik
        initialValues={initialValues}
        validationSchema={LoginSchema}
        onSubmit={(values, { resetForm }) => {
          handleSubmit(values);
          // resetForm();
        }}
      >
        {({ errors, touched }) => (
          <main className="main-content mt-0">
            <div className="page-header align-items-start min-vh-100">
              <span className="mask opacity-6"></span>
              <div className="container my-auto">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-12 mx-auto">
                    <div className="log-logo">
                      <img className="logo" src={logo} alt="" />
                      <h4>Field Tracking</h4>
                    </div>
                    <div className="card z-index-0 fadeIn3 fadeInBottom">
                      {/* <div className="card-header p-0 position-relative mt-n4 mx-3 z-index-2 d-flex justify-content-center">
                        <div className="bg-gradient-dark border-radius-lg w-45">
                          <h5 className="text-white font-weight-bolder text-center shadow-lg p-2 pl-3 pr-3 mb-0">
                            Field Tracking
                          </h5>
                        </div>
                      </div> */}
                      <div className="card-body">
                        <Form role="form" className="text-start login-form">
                          <div className="input-group input-group-outline my-3 mb-5">
                            <label className="form-label w-0 mt-n5">
                              UserId
                            </label>
                            <Field
                              className="form-control w-100"
                              type="text"
                              name="userName"
                            />
                            {errors.userName && touched.userName ? (
                              <div className="error">{errors.userName}</div>
                            ) : null}
                          </div>
                          {/* <div className="input-group input-group-outline my-3 mb-5">
                            <label className="form-label w-0 mt-n5">
                              Email
                            </label>
                            <Field
                              className="form-control w-100"
                              type="text"
                              name="email"
                            />
                            {errors.email && touched.email ? (
                              <div className="error">{errors.email}</div>
                            ) : null}
                          </div> */}
                          <div className="input-group input-group-outline mb-3">
                            <label className="form-label w-0 mt-n5">
                              Password
                            </label>
                            <div className="position-relative w-100">
                              <Field
                                className="form-control w-100"
                                type={passVisible ? "text" : "password"}
                                name="password"
                              />
                              <i
                                className={`fa fa-eye${passVisible ? "" : "-slash"
                                  } eye-pos`}
                                onClick={() => setPassVisible((prev) => !prev)}
                              ></i>
                            </div>
                            {errors.password && touched.password ? (
                              <div className="error">{errors.password}</div>
                            ) : null}
                          </div>
                          <div className="text-center">
                            <button
                              type="submit"
                              className="btn bg-gradient-dark w-100 my-4 mb-2"
                            >
                              Sign in
                            </button>
                          </div>
                          <p className="mt-4 text-sm text-center">
                            Forgot Password
                            <Link
                              to={EndPoints.FORGOT_PASSWORD}
                              className="mx-1 bg-gradient-dark text-gradient cursior-pointer font-weight-bold"
                            >
                              click here
                            </Link>
                          </p>
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </main>
        )}
      </Formik>
    </>
  );
};

export default Login;
