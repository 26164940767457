import React, { useEffect, useState } from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useLocation, useNavigate } from "react-router-dom";

import { AgentSchema } from "../../Helpers/Constants/Schemas";
import { AgentFields } from "../../Helpers/Constants/InitialValues";

import { EndPoints } from "../../Helpers/Constants/EndPoints";

import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import {
  CREATE,
  UPDATE,
  USER_IDS,
  USER_TYPE,
} from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { toast } from "react-toastify";



const randomSerialNumber = String(Math.floor(Math.random() * 999) + 1).padStart(
  3,
  "0"
);

const Agent = () => {
  const navigate = useNavigate();
  const { state } = useLocation();
  const [randomSerialNumber, setRandomSerialNumber] = useState("");
  useEffect(() => {
    const generateSerialNumber = () => {
      return String(Math.floor(Math.random() * 999) + 1).padStart(3, "0");
    };
    setRandomSerialNumber(generateSerialNumber());
  }, []);
  const [isUpdate] = useState(state ? true : false);
  const [stateLabel, setStateLabel] = useState('');
  const [statesCode, setStatesCode] = useState('');
  const [cityLabel, setCityLabel] = useState('');


  const [addFetchPincode, setAddFetchPincode] = useState(true);
  const [initialValues, setInitialValue] = useState(
    isUpdate ? state : AgentFields.required
  );
  const [dropdown, setDropdown] = useState({
    supervisors: [],
    countries: [],
    states: [],
    cities: [],
  });


  const handleSubmit = async (data, setSubmitting) => {
    delete data.email;
    delete data.phoneNumber;
    delete data.name;

    const { state, city, pincode, address, ...rest } = data;
    const selectedCity = cityOptions.find(item => item.label === city);

    const payload = {
      ...rest,
      address: {
        state: stateLabel,
        pincode: address.pincode,
        address: address.address,
        stateCode: statesCode,
        city: selectedCity ? selectedCity.label : city,
        country: "India",
      },
    };

    try {
      const { status } = await Axios.post(API.AGENT.CREATE, payload);
      console.log(status);
      if (status) navigate(EndPoints.AGENTS);
    } catch (err) {
      console.log("ERROR==>", err);
      setSubmitting(false);
    }
  };

  const getDropdowns = async () => {
    try {
      const { data, status } = await Axios.get(API.DROPDOWN.USER, {
        accountType: USER_TYPE.SUPERVISOR,
      });

      if (status) setDropdown((prev) => ({ ...prev, supervisors: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchCountries = async () => {
    try {
      const { data, status } = await Axios.get(API.CSC.COUNTRIES);
      if (status) setDropdown((prev) => ({ ...prev, countries: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchStates = async (parentId) => {
    try {
      const { data, status } = await Axios.get(API.CSC.STATES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, states: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const fetchCities = async (e, setFieldValue, values) => {
    try {
      const parentId =
        e.target?.options[e.target?.selectedIndex]?.dataset?.parentid,
        stateCode =
          e.target?.options[e.target?.selectedIndex]?.dataset?.statecode;

      setInitialValue((prev) => ({
        ...prev,
        address: { ...prev.address, stateCode },
      }));

      if (!isUpdate) {
        const userName = generateUserId(values.name, stateCode);
        setFieldValue("userName", userName);
      }

      const { data, status } = await Axios.get(API.CSC.CITIES, { parentId });
      if (status) setDropdown((prev) => ({ ...prev, cities: data }));
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeAddress = async (pincode, setFieldValue, values) => {
    try {
      let stateCode = "";

      setFieldValue("address.pincode", pincode);

      if (pincode.length === 6) {
        const data = await Axios.get(API.COMMON.ADDRESS_API, {
          pincode,
        });

        if (data?.status) {
          setAddFetchPincode(true);

          setFieldValue("address.country", data?.data?.country || "");
          setFieldValue("address.state", data?.data?.state || "");
          setFieldValue("address.city", data?.data?.city || "");
          setFieldValue("address.address", data?.data?.formatted_address || "");

          stateCode = data?.data?.state_code || "";

          setInitialValue((prev) => ({
            ...prev,
            address: { ...prev.address, stateCode },
          }));
        } else {
          setAddFetchPincode(false);

          dropdown.countries.length || fetchCountries();

          setFieldValue("address.country", "");
          setFieldValue("address.state", "");
          setFieldValue("address.city", "");
          setFieldValue("address.address", "");
        }

        if (!isUpdate) {
          // const userName = generateUserId(values.name, stateCode);
          // setFieldValue("userName", userName);
        }
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const handleChangeUserId = async (firstName, setFieldValue) => {
    try {
      console.log(firstName)
      setFieldValue("firstName", firstName);

      const userName = generateUserId(firstName, initialValues.address?.stateCode);
      const userEmail = generateEmailId(firstName);
      setFieldValue("userName", userName);
      setFieldValue("email", userEmail);
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };

  const generateEmailId = (name) => {
    let userEmail = name?.toLowerCase()?.replace(/\s/g, "");
    if (userEmail) {
      userEmail = `${userEmail}${randomSerialNumber}@gmail.com`;
    }

    return userEmail;
  };

  const generateUserId = (name, stateCode) => {
    let userName = name?.toLowerCase()?.replace(/\s/g, "");

    if (userName) {
      userName = `${userName}${randomSerialNumber}`;
      userName = stateCode
        ? `${userName}.${stateCode}.${USER_IDS.AGENT}`
        : userName;
    }

    return userName;
  };

  const handleUpdateDocument = async (data, setSubmitting) => {
    try {
      delete data.phoneNumber;
      delete data.email;
      delete data.userName;
      delete data.name;
  
      const { state, city, stateCode, pincode, address, userList } = data;
      console.log("City from form data:", city);  

      const addressObject = {};
      if (stateLabel) {
        addressObject.state = stateLabel;
      }
      if (statesCode) {
        addressObject.stateCode = statesCode;
      }
      if (address.pincode) {
        addressObject.pincode = address.pincode;
      }
      if (address.address) {
        addressObject.address = address.address;
      }

      if (city) {
        const selectedCity = cityOptions.find(item => item.label === city);
        addressObject.city = selectedCity ? selectedCity.label : city;
        console.log("City added to addressObject:", addressObject.city);  
      } else {
        addressObject.city = initialValues?.address?.city
        console.log("No city selected."); 
      }

      if (Object.keys(addressObject).length > 0) {
        addressObject.country = "India";
      }

      console.log("Address Object:", addressObject);


      const params = await UpdateParam(AgentFields, data, []);
      if (Object.keys(addressObject).length > 0) {
        params.address = addressObject;
      }

      params.userList = userList;

      const { status } = await Axios.patch(API.AGENT.UPDATE, {
        agentId: initialValues._id,
        firstName: data.firstName,
        lastName: data.lastName,
        ...params,
      });

      if (status) navigate(EndPoints.AGENTS);
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setSubmitting(false);
    }
  };


  const handlePassword = (value, setFieldValue) => {
    if (value.length > 4) {
      toast.error("Password Pin must be only 4 digits", {
        position: "bottom-center",
      });
    } else {
      setFieldValue("password", value);
    }
  };

  useEffect(() => {
    getDropdowns();
  }, []);


  const [visible, setVisible] = useState({ loader: false });
  const [statesAndUTs, setStatesAndUTs] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);

  const [filter, setFilter] = useState({});

  const getStates = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.LOCATION.STATES);
      if (status) {
        setStatesAndUTs(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  const getCities = async (stateCode) => {
    if (!stateCode) {
      console.log("State code is required to fetch cities.");
      return;
    }

    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(`${API.LOCATION.CITY}?stateCode=${stateCode}`);
      if (status) {
        setCityOptions(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  useEffect(() => {

    if (isUpdate) {
      if(initialValues.address.state){
        setStatesCode(initialValues.address.stateCode)
        setStateLabel(initialValues.address.state)
        setCityLabel(initialValues.address.city)
      }
    }
  }, [isUpdate, initialValues]);

  useEffect(() => {
    getStates();
  }, [filter]);



  return (
    <Formik
      initialValues={initialValues}
      //validationSchema={AgentSchema}
      onSubmit={(values, { resetForm, setSubmitting }) => {
        isUpdate
          ? handleUpdateDocument(values, setSubmitting)
          : handleSubmit(values, setSubmitting);
        // resetForm();
      }}
    >
      {({
        isSubmitting,
        errors,
        touched,
        values,
        setFieldValue,
        handleChange,
      }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>First Name</label>
                <Field
                  className="form-control"
                  type="text"
                  name="firstName"
                  onChange={(e) =>
                    handleChangeUserId(e?.target?.value, setFieldValue)
                  }
                  disabled={isUpdate}
                />
                {errors.firstName && touched.firstName ? (
                  <div className="error">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>Last Name</label>
                <Field className="form-control" type="text" name="lastName" />
                {errors.lastName && touched.lastName ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>



              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field
                  className="form-control"
                  type="number"
                  name="password"
                  onChange={(e) =>
                    handlePassword(e?.target?.value, setFieldValue)
                  }
                />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>

              {/* <div className="col-md-4 mb-3">
                <label>Mobile Number</label>
                <Field
                  className="form-control"
                  type="number"
                  name="phoneNumber"
                />
          
              </div> */}

              <div className="col-md-4 mb-3">
                <label>Supervisor</label>
                <Field className="form-control" name="parentId" as="select">
                  <option value="">Click to Select</option>
                  {dropdown.supervisors?.map((val) => (
                    <option key={val._id} value={val._id}>
                      {val.name}
                    </option>
                  ))}
                </Field>
                {errors.parentId && touched.parentId ? (
                  <div className="error">{errors.parentId}</div>
                ) : null}
              </div>

              <div className="col-md-4 mb-3">
                <label>User Id</label>
                <Field
                  className="form-control"
                  type="text"
                  name="userName"
                  disabled={true}
                />
                {errors.userName && touched.userName ? (
                  <div className="error">{errors.userName}</div>
                ) : null}
              </div>

              <div className="col-md-12 mb-3">
                <h3>Address</h3>
                <div className="row">
                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label htmlFor="address.pincode">Pincode</label>
                      <Field
                        type="number"
                        className="form-control"
                        name="address.pincode"
                        // onChange={(e) =>
                        //   handleChangeAddress(
                        //     e?.target?.value,
                        //     setFieldValue,
                        //     values
                        //   )
                        // }
                      />
                      {errors.address?.pincode && touched.address?.pincode ? (
                        <div className="error">{errors.address?.pincode}</div>
                      ) : null}
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <div className="form-group">
                      <label htmlFor="address.address">Address</label>
                      <Field
                        type="text"
                        className="form-control"
                        name="address.address"
                      />
                    </div>
                  </div>

                  <div className="col-md-6 mb-3">
                    <label htmlFor="state">State</label>
                    <Field
                      as="select"
                      className="form-control"
                      name="state"
                      onChange={e => {
                        const selectedStateCode = e.target.value;
                        const selectedState = statesAndUTs.find(item => item.value === selectedStateCode);
                        console.log("=============>",selectedState,selectedStateCode)
                        if (selectedState) {
                          setStateLabel(selectedState.label);
                          setStatesCode(selectedState.value);
                          getCities(selectedStateCode);
                          const userName = generateUserId(values.firstName, selectedStateCode);
                          console.log(values)
                          console.log(userName)
                          setFieldValue("userName", userName);
                        }
                      }}
                    >
                      <option value={isUpdate ? initialValues?.address?.state : ""} label={isUpdate ? initialValues?.address?.state : "Select state"} />
                      {statesAndUTs.map((state, index) => (
                        <option key={index} value={state.value}>
                          {state.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="state" component="div" className="error" />
                  </div>

                  <div className="col-md-6 mb-3">
                    <label>City</label>
                    <Field as="select" name="city" className="form-control">
                      <option value={isUpdate ? initialValues?.address?.city : ""} label={isUpdate ? initialValues?.address?.city : "Select a city"}></option>
                      {cityOptions.map((city, index) => (
                        <option key={index} value={city.label}>
                          {city.label}
                        </option>
                      ))}
                    </Field>
                    <ErrorMessage name="city" component="div" className="error" />
                  </div>
                </div>
              </div>


              <div className="col-md-12 mb-3 mt-3">
                <button
                  //disabled={isSubmitting}
                  className="btn bg-gradient-dark d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>
  );
};

export default Agent;
