import React, { useState, useEffect } from "react";
import { useSelector } from 'react-redux';
import { Formik, Form, Field, ErrorMessage, FieldArray } from "formik";
import { useLocation, useNavigate } from "react-router-dom";
import { SubAdminSchema, SuperAdminSchema } from "../../Helpers/Constants/Schemas";
import { SubAdminFields, SuperAdminFields } from "../../Helpers/Constants/InitialValues";
import { EndPoints } from "../../Helpers/Constants/EndPoints";
import API from "../../Helpers/Constants/EndPoints/Api";
import Axios from "../../Helpers/Universal/Axios/Axios";
import { CREATE, UPDATE } from "../../Helpers/Constants/Default";
import { UpdateParam } from "../../Helpers/Universal/Function/common";
import { LIMIT1, NA, OFFSET } from "../../Helpers/Constants/Default";
import { Country, State, City } from 'country-state-city';
import CustomSelect from "./CustomSelect";
import SuperadminPermission from "../Common/SuperadminPermission";
const SuperAdmin = () => {
  const paginate = useSelector((val) => val.paginate);
  const [data, setData] = useState([]);
  const [roleId, setRoleId] = useState("");
  const [subAdminId, setSubAdminId] = useState("");
  const [permissions, setPermissions] = useState([]);
  const [stateLabel, setStateLabel] = useState('');
  const [statesCode, setStatesCode] = useState('');
  const [visible, setVisible] = useState({ loader: false });
  const [filter, setFilter] = useState({
    limit: LIMIT1,
    offset: paginate?.subAdmin || OFFSET,
  });

  const superpermission = [
    {
      "field": "agents",
      "name": "Agents",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "supervisors",
      "name": "Supervisors",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "managers",
      "name": "Managers",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "subAdmins",
      "name": "Area Head",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "admins",
      "name": "Regional Head",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "superAdmins",
      "name": "Root User",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "notify",
      "name": "Notification",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "config",
      "name": "Config",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "superLogout",
      "name": "Super Logout",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    },
    {
      "field": "cms",
      "name": "CMS",
      "all": false,
      "view": false,
      "add": false,
      "edit": false,
      "delete": false
    }
  ]


  useEffect(() => {
    const city = City.getCitiesOfState("IN")
    console.log(city);
  }, [])
  const [admindata, setAdminData] = useState([]);

  const [employees, setEmployees] = useState([]);
  const [selectedemployees, setSelectedEmployees] = useState([]);

  const [managers, setManagers] = useState([]);
  const [selectedManager, setSelectedManager] = useState(null);
  const getData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.SUPERADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });
      console.log(data.managers)
      console.log(data)
      console.log(status)
      if (status === true) {
        setData(data);
        setManagers(data.data || []);
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  const getAdminData = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));

      const { status, data } = await Axios.get(API.ADMIN.LISTING, {
        limit: filter.limit,
        offset: filter.offset,
      });
      console.log(admindata.managers)
      console.log(admindata)
      console.log(status)
      if (status === true) {
        setAdminData(data);
        setEmployees(data.data || []);
      }

    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };

  useEffect(() => {
    console.log("Filter changed:", filter);
    getData();
  }, [filter]);

  useEffect(() => {
    console.log("Filter changed:", filter);
    getAdminData();
  }, [filter]);

  const handleManagerChange = (event) => {
    const selectedValue = event.target.value;
    const selectedManagerObject = managers.find(manager => manager._id === selectedValue);
    setSelectedManager(selectedManagerObject);
  };

  const handleEmployeeChange = (event) => {
    const selectedValue = event.target.value;
    const selectedEmployeeObject = employees.find(emp => emp._id === selectedValue);

    if (selectedEmployeeObject) {
      setSelectedEmployees(prevSelected => {
        if (prevSelected.some(emp => emp._id === selectedValue)) {
          return prevSelected.filter(emp => emp._id !== selectedValue);
        } else {
          return [...prevSelected, selectedEmployeeObject];
        }
      });
    }
  };



  const navigate = useNavigate();
  const { state } = useLocation();


  const [statesAndUTs, setStatesAndUTs] = useState([]);

  const [customState, setCustomState] = useState('');

  const [cities, setCities] = useState([]);
  const [newCity, setNewCity] = useState('');
  const [state1, setState1] = useState("");
  const [city1, setCity1] = useState("");
  const [state2, setState2] = useState([]);
  const [city, setCity] = useState([]);

  useEffect(() => {
    setStatesAndUTs(State.getStatesOfCountry("IN"))
  }, [])


  const getStates = async () => {
    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(API.LOCATION.STATES);
      if (status) {
        setStatesAndUTs(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };


  const getCities = async (stateCode) => {
    if (!stateCode) {
      console.log("State code is required to fetch cities.");
      return;
    }

    try {
      setVisible((prev) => ({ ...prev, loader: true }));
      const { status, data } = await Axios.get(`${API.LOCATION.CITY}?stateCode=${stateCode}`);
      if (status) {
        setCityOptions(data || []);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    } finally {
      setVisible((prev) => ({ ...prev, loader: false }));
    }
  };



  useEffect(() => {
    getStates();
  }, [filter]);


  const validateUserName = (value) => {
    const regex = /^[a-zA-Z0-9.]*$/;
    if (!regex.test(value)) {
      return "User ID must be alphanumeric and may include periods.";
    }
    return undefined;
  };



  const [isUpdate] = useState(state ? true : false);
  const [initialValues] = useState(isUpdate ? { ...state, permissions: superpermission } : { ...SuperAdminFields.required, permissions: superpermission });
  const typeToRoute = {
    SUPERADMIN: {
      create: EndPoints.ADD_SUPER_ADMIN,
      update: EndPoints.EDIT_SUPER_ADMIN,
    },
    ADMIN: {
      create: EndPoints.ADD_ADMIN,
      update: EndPoints.EDIT_ADMIN,
    },
    SUBADMIN: {
      create: EndPoints.ADD_SUB_ADMIN,
      update: EndPoints.EDIT_SUB_ADMIN,
    },
    MANAGER: {
      create: EndPoints.ADD_MANAGER,
      update: EndPoints.EDIT_MANAGER,
    },
    SUPERVISOR: {
      create: EndPoints.ADD_SUPERVISOR,
      update: EndPoints.EDIT_SUPERVISOR,
    },
    AGENT: {
      create: EndPoints.ADD_AGENT,
      update: EndPoints.EDIT_AGENT,
    },
  };

  console.log(statesCode)
  // const handleSubmit = async (data) => {
  //   const { state, city, ...rest } = data;
  //   delete rest.name
  //   const payload = {
  //     ...rest,
  //     address: {
  //       state: stateLabel,
  //       stateCode: statesCode,
  //       city,
  //       country: "India",
  //     },
  //   };

  //   if (selectedManager) {
  //     payload.parentId = selectedManager._id;
  //   }

  //   if (selectedemployees.length > 0) {
  //     payload.userList = selectedemployees.map(emp => emp._id);
  //   }

  //   try {
  //     const { status } = await Axios.post(API.SUPERADMIN.CREATE, payload);
  //     if (status) {
  //       navigate(EndPoints.SUPER_ADMINS);
  //     }
  //   } catch (err) {
  //     console.log("ERROR==>", err);
  //   }
  // };

  const handleSubmit = async (data) => {
    const { state, city, ...rest } = data;
    delete rest.name;
  
    const selectedCity = cityOptions.find(item => item.label === city);
  
    const payload = {
      ...rest,
      address: {
        state: stateLabel,
        stateCode: statesCode,
        city: selectedCity ? selectedCity.label : city, 
        country: "India",
      },
    };
  
    if (selectedManager) {
      payload.parentId = selectedManager._id;
    }
  
    if (selectedemployees.length > 0) {
      payload.userList = selectedemployees.map(emp => emp._id);
    }
  
    try {
      const { status } = await Axios.post(API.SUPERADMIN.CREATE, payload);
      if (status) {
        navigate(EndPoints.SUPER_ADMINS);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };
  

  const handleUpdateDocument = async (data) => {
    const { state, city, stateCode, userList } = data;

    console.log(data)
    console.log(initialValues)
    const addressObject = {};

    if (stateLabel) {
      addressObject.state = stateLabel;
    }
    if (statesCode) {
      addressObject.stateCode = statesCode;
    }
    if (city) {
      const selectedCity = cityOptions.find(item => item.label === city);
      addressObject.city = selectedCity ? selectedCity.label : city; 
    }


    if (Object.keys(addressObject).length > 0) {
      addressObject.country = "India";
    }

    const params = {
      subAdminId: initialValues._id,
      //name: data.name,
      //email: initialValues?.email,
      firstName: data.firstName,
      lastName: data.lastName,
      userName: initialValues?.userName,
      password: data.password,
      isBlocked: initialValues.isBlocked,
      roleId: initialValues.roleId._id,
      permissions: initialValues.roleId.permissions,
      userList: userList
    };

    if (Object.keys(addressObject).length > 0) {
      params.address = addressObject;
    }

    if (selectedManager) {
      params.parentId = selectedManager._id;
    }

    if (selectedemployees.length > 0) {
      params.userList = selectedemployees.map(emp => emp._id);
    }

    try {
      const { status } = await Axios.patch(API.SUPERADMIN.UPDATE, params);
      if (status) {
        navigate(typeToRoute[data.type].update);
      }
    } catch (err) {
      console.log("ERROR==>", err);
    }
  };
  const [selectedState, setSelectedState] = useState('');
  const [cityOptions, setCityOptions] = useState([]);

  // const handleStateChange = (event) => {
  //   const [state, name] = event.target.value.split("_");
  //   console.log(state)
  //   setSelectedState(City.getCitiesOfState("IN", state));
  //   setState2(name)
  //   console.log(name)
  // };

  console.log(statesAndUTs)

  console.log(initialValues)
  return (
    <Formik
      initialValues={initialValues}
      //validationSchema={SuperAdminSchema}
      onSubmit={(values, { resetForm }) => {
        isUpdate ? handleUpdateDocument(values) : handleSubmit(values);
        // resetForm();
      }}
    >
      {({ isSubmitting, errors, touched, setFieldValue, values }) => (
        <Form className="page-from">
          <div className="inner-form">
            <div className="row">
              <div className="col-md-4 mb-3">
                <label>Type</label>
                <Field as="select" className="form-control" name="type" onChange={(event) => {
                  const valueType = event.target.value;
                  const route = typeToRoute[valueType];
                  if (isUpdate) {
                    navigate(route.update);
                  } else {
                    navigate(route.create);
                  }
                }}>
                  <option value="SUPERADMIN" label="ROOT USER/SUPERADMIN" />
                  <option value="SUBADMIN" label="AREA HEAD/SUBADMIN" />
                  <option value="ADMIN" label="REGIONAL HEAD/ADMIN" />
                  <option value="MANAGER" label="MANAGER" />
                  <option value="SUPERVISOR" label="SUPERVISOR" />
                  <option value="AGENT" label="AGENT" />
                </Field>
                {errors.type && touched.type ? (
                  <div className="error">{errors.type}</div>
                ) : null}
              </div>

              {/* <div className="col-md-4 mb-3">
                <label>Name</label>
                <Field className="form-control" type="text" name="name" />
                {errors.name && touched.name ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div> */}
              <div className="col-md-4 mb-3">
                <label>First Name</label>
                <Field className="form-control" type="text" name="firstName" />
                {errors.firstName && touched.firstName ? (
                  <div className="error">{errors.firstName}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>Last Name</label>
                <Field className="form-control" type="text" name="lastName" />
                {errors.lastName && touched.lastName ? (
                  <div className="error">{errors.name}</div>
                ) : null}
              </div>
              <div className="col-md-4 mb-3">
                <label>UserId</label>
                <Field
                  className="form-control"
                  type="text"
                  name="userName"
                  disabled={isUpdate}
                  validate={validateUserName}

                />
                {errors.userName && touched.userName ? (
                  <div className="error">{errors.userName}</div>
                ) : null}
              </div>


              <div className="col-md-4 mb-3">
                <label>Password</label>
                <Field className="form-control" type="text" name="password" />
                {errors.password && touched.password ? (
                  <div className="error">{errors.password}</div>
                ) : null}
              </div>



              <div className="col-md-4 mb-3">
                <label htmlFor="state">State</label>
                <Field
                  as="select"
                  className="form-control"
                  name="state"
                  onChange={e => {
                    const selectedStateCode = e.target.value;

                    const selectedState = statesAndUTs.find(item => item.value === selectedStateCode);

                    if (selectedState) {
                      setStateLabel(selectedState.label);
                      setStatesCode(selectedState.value)
                      getCities(selectedStateCode);
                    }
                  }}
                >
                  <option value={isUpdate ? initialValues?.address?.state : ""} label={isUpdate ? initialValues?.address?.state : "Select state"} />
                  {statesAndUTs.map((state, index) => (
                    <option key={index} value={state.value}>
                      {state.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="state" component="div" className="error" />
              </div>




              <div className="col-md-4 mb-3">
                <label>City</label>
                <Field as="select" name="city" className="form-control">
                <option value={isUpdate ? initialValues?.address?.city : ""} label={isUpdate ? initialValues?.address?.city :"Select a city"}></option>
                  {cityOptions.map((city, index) => (
                    <option key={index} value={city.label}>
                      {city.label}
                    </option>
                  ))}
                </Field>
                <ErrorMessage name="city" component="div" className="error" />
              </div>


              {!isUpdate && (
                <FieldArray name="permissions">
                  {() => (
                    <table className="table table-bordered text-center">
                      <thead className="bg-light text-dark">
                        <tr>
                          <th scope="col">Permissions</th>
                          <th scope="col">All</th>
                          <th scope="col">View</th>
                          <th scope="col">Add</th>
                        </tr>
                      </thead>
                      <tbody>
                        {values?.permissions?.map((val, index) => (
                          <tr key={index}>
                            <td>{val.name}</td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].all`}
                                checked={val.all}
                                onChange={(e) => {
                                  const check = !values?.permissions[index].all;
                                  setFieldValue(`permissions[${index}].all`, check);
                                  setFieldValue(`permissions[${index}].view`, check);
                                  setFieldValue(`permissions[${index}].add`, check);
                                }}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].view`}
                                checked={val.view ?? val.all}
                                onChange={(e) => {
                                  const check = !values?.permissions[index].view;
                                  setFieldValue(`permissions[${index}].view`, check);
                                  if (!check) {
                                    setFieldValue(`permissions[${index}].all`, false);
                                  }
                                }}
                              />
                            </td>
                            <td>
                              <Field
                                className="form-check-input"
                                type="checkbox"
                                name={`permissions[${index}].add`}
                                checked={val.add ?? val.all}
                                onChange={(e) => {
                                  const check = !values?.permissions[index].add;
                                  setFieldValue(`permissions[${index}].add`, check);

                                  // If 'add' is unchecked, uncheck 'all'
                                  if (!check) {
                                    setFieldValue(`permissions[${index}].all`, false);
                                  }
                                }}
                              />
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  )}
                </FieldArray>
              )}

              <div className="col-md-12 mb-3">
                <button
                  //disabled={isSubmitting}
                  className="btn bg-gradient-dark mt-3 d-block btn-lg mb-1"
                  type="submit"
                >
                  {isUpdate ? UPDATE : CREATE}
                </button>
              </div>
            </div>
          </div>
        </Form>
      )}
    </Formik>

  );
};

export default SuperAdmin;





