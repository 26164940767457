import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  id: "",
  name: "",
  email: "",
  role: "",
  permissions: [],
  isLogin: false,
};

const profileSlice = createSlice({
  name: "profile",
  initialState: initialState,
  reducers: {
    setProfile(state, value) {
      state.id = value?.payload?._id;
      state.name = value?.payload?.name;
      state.email = value?.payload?.email;
      state.role = value?.payload?.accountType == 3 ? "SUB_ADMIN" : value?.payload?.accountType == 2 ? "ADMIN" : "SUPER_ADMIN";
      state.accountType = value?.payload?.accountType;
      state.permissions = value?.payload?.roleId?.permissions;
    },
  },
});

export const { setProfile } = profileSlice.actions;
export default profileSlice.reducer;
